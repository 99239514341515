import React, { Component } from "react";
import IframeResizer from "iframe-resizer-react";

class Flowers extends Component {
    render() {
        let iFrameURL;
        if (process.env.REACT_APP_CORS_MODE === "dev") {
            iFrameURL = "https://appmais-dev.cs.appstate.edu/FlowersApp";
        } else {
            iFrameURL = "https://appmais.cs.appstate.edu/FlowersApp";
        }
        return (
            <div>
                <IframeResizer
                    log
                    heightCalculationMethod="bodyScroll"
                    src={iFrameURL}
                    scrolling="yes"
                    style={{ width: "1px", minWidth: "100%", height: "85vh" }}
                />
            </div>
        );
    }
}

export default Flowers;
