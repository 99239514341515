import React, { useState } from "react";
import "./Nav.css";
/**
 * Nav bar component
 * This is the navigation bar that is displayed at the top of the page that directs the user to different <Route>s
 * See App.jsx for the <Route>s
 */
export default function Nav() {
    const [IsDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!IsDropdownOpen);
    };
    // tbh I don't really understand <svg>s, it is legacy code. Sorry future devs.
    // see https://www.w3schools.com/graphics/svg_path.asp for more information on SVG paths
    let navSvgImage = (
        <svg
            className="nav-button-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </svg>
    );

    return (
        <div className="navbar">
            <div className="navbar-start">
                <div className="dropdown">
                    <label
                        tabIndex="0"
                        className="btn btn-ghost lg:hidden"
                        onClick={toggleDropdown}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h8m-8 6h16"
                            />
                        </svg>
                    </label>
                    {IsDropdownOpen && (
                        <ul className="menu menu-compact dropdown-content">
                            <li>
                                <a onTouchEnd={() => window.location.href = '/Teams/'} href="/Teams/">Team</a>
                            </li>
                            <li>
                                <a onTouchEnd={() => window.location.href = '/Overview/'} href="/Overview/">Overview</a>
                            </li>
                            <li>
                                <a onTouchEnd={() => window.location.href = '/Publications/'}
                                   href="/Publications/">Publications</a>
                            </li>
                            <li>
                                <a onTouchEnd={() => window.location.href = '/Presentations/'}
                                   href="/Presentations/">Presentations</a>
                            </li>
                            <li>
                                <a onTouchEnd={() => window.location.href = '/Live/'} href="/Live/">Live Stream</a>
                            </li>
                            <li>
                                <a onTouchEnd={() => window.location.href = '/Archive/'} href="/Archive/">Archive</a>
                            </li>
                            <li>
                                <a onTouchEnd={() => window.location.href = '/Media/'} href="/Media/">Media</a>
                            </li>
                            <li>
                                <a onTouchEnd={() => window.location.href = '/InterestingEvents/'} href="/InterestingEvents/">InterestingEvents</a>
                            </li>
                            <li>
                                <a onTouchEnd={() => window.location.href = '/Flowers/'} href="/Flowers/">Flowers</a>
                            </li>
                            <li>
                                <a
                                    onTouchEnd={() => window.location.href = '/http://appmais.cs.appstate.edu:8081/dashboard/56b56840-e108-11ec-a4b2-f50e8dc46689?publicId=de49f250-ea30-11eb-a587-63f1ebdeaad7/'}
                                    rel="noreferrer"
                                    target="_blank"
                                    href="http://appmais.cs.appstate.edu:8081/dashboard/56b56840-e108-11ec-a4b2-f50e8dc46689?publicId=de49f250-ea30-11eb-a587-63f1ebdeaad7"
                                >
                                    Dashboard
                                </a>
                            </li>

                        </ul>
                    )}
                </div>
                <a className="btn btn-ghost normal-case text-xl" href="/">
                    AppMAIS
                </a>
            </div>
            <div className="navbar-center hidden lg:flex">
                {/*This is the list of buttons at the top like "Teams", "Dashboard", etc.*/}
                <ul className="menu menu-horizontal p-0">
                    <li tabIndex="0">
                        <a href="/Teams/">Team</a>
                    </li>
                    <li tabIndex="0">
                        <a>Research {navSvgImage}</a>
                        {/*When a button has a dropdown option, it will have its own <ul> list*/}
                        <ul className="nav-dropdown-menu">
                            <li>
                                <a href="/Overview/">Overview</a>
                            </li>
                            <li>
                                <a href="/Publications/">Publications</a>
                            </li>
                            <li>
                                <a href="/Presentations/">Presentations</a>
                            </li>
                        </ul>
                    </li>
                    <li tabIndex="0">
                        <a>Beestream {navSvgImage}</a>
                        <ul className="nav-dropdown-menu">
                            <li>
                                <a href="/Live/">Live Stream</a>
                            </li>
                            <li>
                                <a href="/Archive/">Archive</a>
                            </li>
                        </ul>
                    </li>
                    <li tabIndex="0">
                        <a href="/Media/">Media</a>
                    </li>

                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="http://appmais.cs.appstate.edu:8081/dashboard/56b56840-e108-11ec-a4b2-f50e8dc46689?publicId=de49f250-ea30-11eb-a587-63f1ebdeaad7"
                        >
                            Dashboard
                        </a>
                    </li>
                    <li tabIndex="0">
                        <a href="/InterestingEvents/">Interesting Events</a>
                    </li>
                    <li tabIndex="0">
                        <a href="/Flowers/">Flowers</a>
                    </li>
                    <li tabIndex="0">
                        <a href="/docs/">Documentation</a>
                    </li>
                </ul>
            </div>
            <div className="navbar-end ">
                <a className="btn btn-secondary" href="/Login/">
                    Login
                </a>
            </div>
        </div>
    );
}
